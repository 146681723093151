<template>
  <div v-if="canAccess('liquidaciones_read')">
    <div class="p-grid">
      <div class=" p-col-12 card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <Toolbar class="p-col-12">

          <template #left>

            <div class="p-fluid filter p-ml-2">
              <div class="p-grid p-fluid filter p-ml-2">

                <Button label="Generar Liquidaciones" icon="pi pi-plus" class="p-button-success"
                        @click="generarDialogo" style="width:200px;"/>
              </div>

              <div class="p-grid p-fluid filter p-ml-2">

                <div class="p-field p-fluid  p-mr-2">
                                    <span class="p-float-label">
                                        <InputText id="enroll" v-model="search" @keyup.enter="filter(search)"/>
                                        <label for="enroll">Buscar por</label>
                                    </span>
                </div>
                <div class="p-field p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown id="status" v-model="filters.status" :options="status"
                                                 @change="filter"/>
                                        <label for="status">Estado</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedC" :options="countries" optionLabel="country"
                                                 @change="selectedM=null;selectedW=null;pLiquidations=[];filter()"/>
                                        <label for="country">País</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedM" :options="months" optionLabel="month_process"
                                                 @change="selectedW=null;pLiquidations=[];filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedW" :options="weeks" optionLabel="week_process"
                                                 @change="pLiquidations=[];filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
                </div>

                <div class="p-field   p-fluid  p-ml-2">
                  <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                          @click="reload"/>
                </div>
                <div class="p-field   p-fluid  p-ml-2">
                  <Button label="Exportar" icon="pi pi-file-excel" class="p-button-success p-mr-2"
                          @click="exportReport('XLS')"/>
                </div>
                <div class="p-field   p-fluid  p-ml-2">
                    <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2"
                          @click="exportReport('PDF')"/>
                </div>
                <!-- <div class="p-field   p-fluid  p-ml-2">
                    <Button label="Filtrar" icon="pi pi-search" class="p-button-info p-mr-2"
                    @click="filter(search)"/>
                </div> -->
              </div>

            </div>
          </template>
        </Toolbar>
      </div>
      <DataTable :value="pLiquidations" :paginator="true" dataKey="id"
                 v-model:selection="currentItem" selectionMode="single"
                 @row-select="selectData($event)"
                 paginatorPosition="bottom"
                 :lazy="true"
                 :totalRecords="page.total"
                 :rows="page.per_page"
                 :loading="loading"
                 @page="onPage($event)">
        <Column header="Cons. No" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <router-link :to="'/liquidation/detail/'+slotProps.data.id">{{ slotProps.data.id }}</router-link>
            </div>
          </template>
        </Column>
        <Column header="País" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.country?.country_name??'-' }}</div>
          </template>
        </Column>
        <Column header="Nombres Asesor" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.employee_name }}<span class="p-badge p-badge-success p-m-1" v-if="slotProps.data.in_debut===1">debut</span></div>
          </template>
        </Column>
        <Column header="Mes" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_month?.year_process }} - {{ slotProps.data.liquidation_month?.month_process }}
            </div>
          </template>
        </Column>
        <Column header="Semana" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_week?.week_process ?? '-'}}</div>
          </template>
        </Column>
        <Column header="Total" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.total) }}</div>
          </template>
        </Column>
        <Column header="Neto a Pagar" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.net_to_pay) }}</div>
          </template>
        </Column>
        <Column header="Fecha Generacion" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ $filters.dateFormat(slotProps.data.generate_at) }}</div>
          </template>
        </Column>
        <Column header="Estado" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="slotProps.data.status"
                   v-if="slotProps.data.status === approvedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="warning" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === validKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-info-circle" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === newKey"></Tag>
              <!-- <Tag class="p-mr-2" rounded icon="pi pi-info-circle" v-else :value="slotProps.data.status"></Tag> -->
            </div>
          </template>
        </Column>
      </DataTable>
      <Dialog v-model:visible="dialogo" style="width: 450px; text-align: center;" header="Generar Liquidaciones"
              :modal="true"
              class="p-fluid">

        <div class="p-field   p-fluid  p-mr-2" >
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedC" :options="countries" optionLabel="country"
                                                 @change="selectedM=null;selectedW=null;filter()"/>
                                        <label for="country">País</label>
                                    </span>
        </div>


        <div class="p-field   p-fluid  p-mr-2">
                            <span class="p-float-label df">
                            <Dropdown id="months" v-model="selectedM" :options="months" optionLabel="month_process"
                                      @change="selectedW=null"/>
                                <label for="months">Mes</label>
                            </span>
        </div>
        <div class="p-field   p-fluid  p-mr-2">
                            <span class="p-float-label df">
                            <Dropdown id="weeks" v-model="selectedW" :options="weeks" optionLabel="week_process"
                                      @change="enable"/>
                                <label for="weeks">Semana</label>
                            </span>
        </div>

        <template #footer style="text-align: center;" class="center">
          <Button v-if="selected" label="Generar" class="p-button-info" @click="generate"/>
          <Button v-if="!selected" label="Generar" class="p-button-secondary"/>
        </template>
      </Dialog>
    </div>
    <Sidebar v-model:visible="panelEnrollmentsPendents" position="right" :showCloseIcon="true" :dismissable="false">
        <h5><b>Matr&iacute;culas Pendientes</b></h5>
        <table style="width: 100%">
          <thead>
            <tr>
              <th class="thC">MATR&Iacute;CULA</th>
              <th class="thC">ESTADO</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in enrollmentsPendents" :key="'tr'+idx">
              <td class="center">
                <div class="center">
                  <router-link :to="'/commission/detail/'+item.id" target="_blank">
                      {{item.sequential}}
                  </router-link>
                </div>
              </td>
              <td class="text-center">
                <div class="center">
                  <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="item.status"
                       v-if="item.status === approvedKey"></Tag>
                  <Tag class="p-mr-2" rounded icon="pi pi-check" severity="warning" :value="item.status"
                       v-else-if="item.status === generatedKey"></Tag>
                  <Tag class="p-mr-2" rounded icon="pi pi-dollar" severity="success" :value="item.status"
                       v-else-if="item.status === payedKey"></Tag>
                  <Tag class="p-mr-2" rounded icon="pi pi-times" severity="danger" :value="item.status"
                       v-else-if="item.status === denyKey"></Tag>
                  <Tag class="p-mr-2" rounded icon="pi pi-times" severity="warning" :value="item.status"
                       v-else-if="item.status === postergedKey"></Tag>
                  <Tag class="p-mr-2" rounded icon="pi pi-info-circle" :value="item.status"
                       v-else-if="item.status === newKey"></Tag>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </Sidebar>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../../service/liquidation.service'
import report from '../../../reports/service/report.service'
import mw from '../../service/mw.service'
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";
import FileSaver from 'file-saver';
export default {
  mixins:[bouncer],
  name: "LiquidationList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      panelEnrollmentsPendents:false,
      columns: [
        {field: 'code', header: 'Codigo'},
        {field: 'date_payment', header: 'Fecha'},
      ],
      page: {
        page: 1,
        per_page: 9,
        total: 0,
        order: "desc"
      },
      allKey: 'Todos',
      newKey: 'Nuevo',
      approvedKey: 'Aprobada',
      validKey: 'Validada',
      denyKey: 'Desaprobada',
      generatedKey: 'Generado',
      postergedKey: 'Postergada',
      payedKey: 'Pagada',
      pLiquidations: [],
      data: {},
      selectedWeek: null,
      selectedMonth: null,
      filters: {'status': 'Todos'},
      submitted: false,
      loading: false,
      dialogo: false,
      verifyDataDialog: false,
      selected: false,
      selectedC: null,
      selectedM: null,
      selectedW: null,
      search: '',
      countries: [],
      report: report('liquidations'),
      enrollmentsPendents:[]
    }
  },
  methods: {
    onPage(event) {
      this.page.page = event.page + 1;
      this.filter(this.search)
    },

    enable() {
      this.selected = true
    },
    getData(params = {}) {
      this.loading = true;
      const config = {
        ...this.page,
        ...params
      };
      mw.getMWLiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.selectedC = this.countries[0];
        }
      })
      service.get(null, config).then(x => {
        this.pLiquidations = x.data.data;
        this.page.total = x.data.total;
      }).finally(() => this.loading = false)
    },
    reload() {
      this.selectedC = null;
      this.selectedW = null;
      this.selectedM = null;
      this.filters = {};
      this.filters = {'status': 'Todos'};
      this.search = '';
      this.getData();
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2).replace('.', ',')
      if (value !== 0) {
        val = "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      return val
    },
    filter(search) {
      this.loading = true;
      const country_id = this.selectedC ? this.selectedC.id : undefined;
      const month_id = this.selectedM ? this.selectedM.id : undefined;
      const week_id = this.selectedW ? this.selectedW.id : undefined;
      const status = this.filters.status !== 'Todos' ? this.filters.status : undefined;

      if (this.search !== '') {
        this.filters.status = this.allKey
        const params = {
          country:country_id,
          months:month_id, weeks:week_id, status,q:search,page:this.page.page
        };
        service.get(undefined, params).then(x => {
          this.pLiquidations = x.data.data;
          this.page.total = x.data.total;
          this.page.page = x.data.current_page;
          this.page.per_page=parseInt( x.data.per_page+'');
        }).finally(() => this.loading = false)

      } else {
        const params = {
          country:country_id,
          months:month_id, weeks:week_id, status
        };
        this.getData(params)
      }
    },
    generarDialogo() {
      this.selectedC=null
      this.selectedM=null
      this.selectedW=null
      this.dialogo = true;
    },
    generate() {
      this.loading = true;
      if (this.generateW !== null) {
        const monthWeek = {
          ...{'country': this.selectedC.id},
          ...{'month': this.selectedM.id},
          ...{'week': this.selectedW.id}
        }
        service.save(monthWeek).then(() => {
          this.selected = false
          this.dialogo = false
          this.getData()
          this.$toast.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Liquidaciones generadas',
            life: this.$utils.toastLifeTime()
          });
          this.$nextTick(() => {
            this.$emit('closeSidebar', false)
          })
        }).catch((err) => {
          if(err.response.status===406){
            const dataError=JSON.parse( err.response.data.message);
            this.enrollmentsPendents=dataError.enrollments;
            this.$toast.add({severity: 'error', summary: 'Error', detail: dataError.msg, life: this.$utils.toastLifeTime()});
            if(this.enrollmentsPendents.length>0){
              this.panelEnrollmentsPendents=true;
            }
          }else{
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          }
        })
            .finally(() => this.loading = false)
      }
    },
    selectData() {
      this.$router.push('detail/' + this.currentItem.id,);
    },
    exportReport(command) {
      this.loading = true;
      this.report.getLiquidations({
        country: this.selectedC ? this.selectedC.id : undefined,
        month: this.selectedM ? this.selectedM.id : undefined,
        week: this.selectedW ? this.selectedW.id : undefined,
        status: this.filters.status !== 'Todos' ? this.filters.status : undefined,
        export: command
      })
          .then(x => {
            if(command=='PDF'){
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});

              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            }else{
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    status() {
      return [this.allKey,
        this.newKey,
        this.approvedKey,
        this.validKey]
    },
    months() {
      const result= this.selectedC ? this.selectedC.months : [];
      if(result.length===0 && this.selectedC){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen meses asociados al país', life: this.$utils.toastLifeTime()});
      }
      return result;
    },
    weeks() {
      const result= this.selectedM ? this.selectedM.weeks : [];
      if(result.length===0  && this.selectedM){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen semanas asociadas al mes', life: this.$utils.toastLifeTime()});
      }
      return result;
    }
  },
  mounted() {
    if(this.canAccess('liquidaciones_read')){
      this.$store.dispatch('bread/setItems', [
        {label: 'Registro', url: "javascript:void(0);"},
        {label: 'Lista de Liquidaciones', url: "javascript:void(0);"},
      ]);
      this.getData();
    }
  }
}
</script>

<style scoped lang="scss">

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.p-selectable-row td:first-child, .uc {
  padding-left: 1.5rem !important;
  display: inline-block;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}
.thC {
  background: #f8f9fa !important;
}
</style>
